import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import withLayout from "../../layout";
import SEO from "../../components/SEO.js";
import WeAreKrominHero from "../../components/Landings/we-are-kromin/WeAreKrominHero.js";
import {Heading, Paragraph} from "../../components/UI/Typography.js";
import Dash from "../../components/UI/Dash.js";
import {ThemeProvider} from "styled-components";
import dark from "../../theme/dark.js";
import {Button} from "../../components/UI/Button.js";
import {useInView} from "react-intersection-observer";
import firstIcon from "../../images/landings/Funnel.json";
import secondIcon from "../../images/landings/Gear.json";
import thirdIcon from "../../images/landings/Magnet.json";
import team from "../../images/landings/Foto_landing.jpg";
import LottieController from "../../components/UI/LottieController.js";
import Image from "../../components/UI/Image.js";
import DigitalTransformationForm from "../../components/Landings/we-are-kromin/DigitalTransformationForm.js";

const ourClients = [
    'clients/Audi.png', 'clients/Enel.png','clients/Bmw.png', 'clients/Carinci.png', 'clients/Maui.png', 'clients/Arken.png'
]

const digitalTransformation = ({intl}) => {
    const keywords = intl.formatMessage({id: 'landingA.meta_keywords'}).split(',') || [];
    const [iconOne, inViewOne] = useInView();
    const [iconTwo, inViewTwo] = useInView();
    const [iconThree, inViewThree] = useInView();

    const scrollDown = () => {
        //safe because this event is fired only when window obj is loaded
        return window?.document?.getElementById('landingAForm').scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div id={"landingA"}>
            <SEO title={intl.formatMessage({id: 'landingA.meta_title'})}
                 description={intl.formatMessage({id: 'landingA.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>

            <WeAreKrominHero titleThin={<FormattedMessage id={"landingA.hero_title_thin"}/>}
                             title={<FormattedMessage id={"landingA.hero_title"}/>}
                             subtitle={<FormattedMessage id={"landingA.hero_subtitle"}/>}
                             cta={<FormattedMessage id={"landingA.hero_main_cta"}/>}
                             scrollCb={scrollDown}
            />

            <div style={{paddingTop: 88}}>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-12">
                            <div style={{maxWidth: 354}}>
                                <Heading level={"2"} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingA.we_make_difference"}/>
                                </Heading>
                            </div>
                            <Dash/>
                            <Paragraph>
                                <FormattedMessage id={"landingA.difference_one"}/>
                            </Paragraph>
                            <Paragraph>
                                <FormattedMessage id={"landingA.difference_two"}/>
                            </Paragraph>
                        </div>
                        <div className="col-md-6 col-12 d-md-block d-none">
                            <Image filename={'landings/groppo_foto.png'} alt={"team"}/>
                        </div>
                        <div className="col-md-6 col-12 d-md-none d-block mt-5">
                            <Image filename={'landings/Foto_Team_2.jpg'} alt={"team"} className="border-radius-pic"/>
                        </div>
                    </div>
                </div>
            </div>

            {/* brand area */}
            <div className="landing-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Heading level={"2"} margin={'0 0 24px 0'}>
                                <FormattedMessage id={"landingA.our_side"}/>
                            </Heading>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center">
                        {ourClients.map((image,index) =>  <div className="col-lg col-sm-4 col-4" key={index}>
                            <Image className="clients-logo" filename={image} key={index} alt={"clients"} />
                        </div>)}
                    </div>

                </div>
            </div>

            <ThemeProvider theme={dark} colors={dark.colors}>
                <div className="landing-padding section-medium-dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 text-center">
                                <Heading level={"2"} thinChild={1} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingA.digitalization_one"}/>
                                    <FormattedMessage id={"landingA.digitalization_two"}/>
                                </Heading>
                                <Dash margin={"0 auto"}/>
                                <Paragraph className="pr-1">
                                    <FormattedMessage id={"landingA.digitalization_message"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>

            <div className="container">
                <div className="row d-flex align-items-center landing-padding">
                    <div className="col-md-6 col-12 order-md-1 order-2">
                        <div>
                            <Heading level={"2"} margin={'0 0 24px 0'}>
                                <FormattedMessage id={"landingA.bullet_one_title"}/>
                            </Heading>
                        </div>
                        <Dash/>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_one_paragraph_1"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_one_paragraph_2"}/>
                        </Paragraph>
                        <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingA.hero_main_cta"}/>} style={{marginTop: 36}}/>
                        </span>
                    </div>
                    <div className="col-md-6 col-12 justify-content-center order-md-2 order-1">
                        <div ref={iconOne}>
                            <LottieController lottieJSON={secondIcon} inView={inViewOne}
                                              height={'400px'} />
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-center landing-padding-desk">
                    <div className="col-md-6 col-12 justify-content-center">
                        <div ref={iconTwo}>
                            <LottieController lottieJSON={firstIcon} inView={inViewTwo}
                                              height={'400px'} />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <Heading level={"2"} margin={'0 0 24px 0'}>
                            <FormattedMessage id={"landingA.bullet_two_title"}/>
                        </Heading>
                        <Dash/>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_two_paragraph_1"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_two_paragraph_2"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_two_paragraph_2"}/>
                        </Paragraph>
                        <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingA.hero_main_cta"}/>} style={{marginTop: 36}}/>
                        </span>
                    </div>
                </div>

                <div className="row d-flex align-items-center landing-padding ">
                    <div className="col-md-6 col-12 order-md-1 order-2">
                        <Heading level={"2"} margin={'0 0 24px 0'}>
                            <FormattedMessage id={"landingA.bullet_three_title"}/>
                        </Heading>
                        <Dash/>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_three_paragraph_1"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_three_paragraph_2"}/>
                        </Paragraph>
                        <Paragraph>
                            <FormattedMessage id={"landingA.bullet_three_paragraph_2"}/>
                        </Paragraph>
                        <span onClick={() => scrollDown()}>
                            <Button type={'primary'} label={<FormattedMessage id={"landingA.hero_main_cta"}/>} style={{marginTop: 36}}/>
                        </span>
                    </div>
                    <div className="col-md-6 col-12 justify-content-center order-md-2 order-1">
                        <div ref={iconThree}>
                            <LottieController lottieJSON={thirdIcon} inView={inViewThree}
                                              height={'400px'} />
                        </div>
                    </div>
                </div>
            </div>

            <ThemeProvider theme={dark} colors={dark.colors}>
                <div className="landing-padding section-medium-dark" id={"landingAForm"}>
                    <div className="container">
                        <div className="row d-flex align-items-start">
                            <div className="col-md-4 col-12 text-sm-center landing-form">
                                <Heading level={"3"} margin={'0 0 24px 0'}>
                                    <FormattedMessage id={"landingA.book_a_consulting_title"}/>
                                </Heading>
                                <Paragraph customSize={16}>
                                    <FormattedMessage id={"landingA.book_a_consulting_message"}/>
                                </Paragraph>
                            </div>
                            <div className="col-md-2 d-md-block d-none"/>
                            <div className="col-md-6 col-12">
                                <DigitalTransformationForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>

        </div>
    );
};
const customProps = {
    localeKey: "landingA", // same as file name in src/i18n/translations/your-lang/index.js,
    isLanding: true
};

export default (withLayout(customProps))(injectIntl(digitalTransformation));
