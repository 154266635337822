import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button} from "../../UI/Button.js";
import * as PropTypes from "prop-types";
const URL = "https://api.kromin.it/v1/request-lp";
import ErrorIcon from "../../../images/form-error-icon.inline.svg";
import SuccessIcon from "../../../images/form-success-icon.inline.svg";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .required(<FormattedMessage id={"contactus.error_required"}/>),
});

SuccessIcon.propTypes = {className: PropTypes.string};
const DigitalTransformationForm = ({intl, formtype}) => {
    const { handleSubmit, register, setError, errors, reset, watch, formState: {isSubmitting}, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            email: '',
            password: ''
        },
        validationSchema
    })
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = async (data) => {
        try {
            await fetch(URL, {
                method: "POST", mode: "cors", cache: "no-cache", body: JSON.stringify({...data, mode: 1}),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            });
            reset();
            setValue('business_type', null);
            setSubmitted(true);
        } catch (error) {
            setError("submit", "submitError", `Contact form erro`);
        }
    };

    const showSubmitError = <p style={{color: "#cd4a4a", border: "1px solid #cd4a4a", fontSize: "13px", lineHeight: "17px",
        padding: "16px", background: "#cd4a4217", fontWeight: 600}} id={"sendError"}>
        <FormattedMessage id={"landingA.message_not_sent"}/>
    </p>

    const showConfirmationSent = (
        <p style={{color: "rgb(71 166 131)", border: "1px solid #47a683", fontSize: "13px", lineHeight: "17px",
            padding: "16px", background: "#54bd8e52", fontWeight: 600}} id={"sendCompleted"}>
            <FormattedMessage id={"landingA.message_sent"}/>
        </p>
    );

    return <>
        <form onSubmit={handleSubmit(onSubmit)} className={"form-transparent"}>

            {/*FIRST NAME*/}
            <input name="first_name" placeholder={intl.formatMessage({id: 'landingA.first_name'})}
                   className={`form-control mt-2 ${errors?.first_name ? "form-error" : "form-success"}`}
                   {...register('first_name')} required={true} disabled={isSubmitting}
            />

            {/*LAST NAME*/}
            <input name="last_name" placeholder={intl.formatMessage({id: 'landingA.last_name'})}
                   className={`form-control mt-2 ${errors?.last_name ? "form-error" : "form-success"}`}
                   {...register('last_name')} required={true} disabled={isSubmitting}
            />

            {/*BUSINESS NAME*/}
            <input name="business_name" placeholder={intl.formatMessage({id: 'landingA.business_name'})}
                   className={`form-control mt-2 ${errors?.business_name ? "form-error" : "form-success"}`}
                   {...register('business_name')} required={true} disabled={isSubmitting}
            />

            {/* EMAIL */}
            <input name="email" placeholder={intl.formatMessage({id: 'landingA.email'})} type={'email'}
                   className={`form-control mt-2 ${errors?.email ? "form-error" : "form-success"}`}
                   {...register('email')} required={true} disabled={isSubmitting}
            />

            {/* PHONE */}
            <input name="phone" placeholder={intl.formatMessage({id: 'landingA.phone'})}
                   className={`form-control mt-2 ${errors?.phone ? "form-error" : "form-success"}`}
                   {...register('phone')} required={true} disabled={isSubmitting}
            />

            {/*BUSINESS TYPE*/}
            <select name="business_type" className={`select-css mt-2 ${errors?.business_type ? "form-error" : "form-success"}`}
                    placeholder={intl.formatMessage({id: 'landingA.business_type'})}
                    {...register('business_type')} required={true} disabled={isSubmitting}
            >
                <option value="" disabled selected>Tipologia di business*</option>
                <option value="Ristrutturazione">Ristrutturazione</option>
                <option value="Educazione (corsi di formazione)">Educazione (corsi di formazione)</option>
                <option value="Studio Legale">Studio Legale</option>
                <option value="Travel Agency">Travel Agency</option>
                <option value="Servizi di assistenza tecnica">Servizi di assistenza tecnica</option>
                <option value="Traslochi">Traslochi</option>
                <option value=" Servizi di pulizie">Servizi di pulizie</option>
                <option value="Altro">Altro</option>
            </select>

            {/*WEBSITE*/}
            <input name="website" placeholder={intl.formatMessage({id: 'landingA.website'})} style={{borderBottomLeftRadius: "25px"}}
                   className={`form-control mt-2 ${errors?.website ? "form-error" : "form-success"}`}
                   {...register('website')} disabled={isSubmitting}
            />

            {/* Check */}
            <label className="check-container mt-4"> Accetto <a href="https://www.iubenda.com/privacy-policy/7814431" target="_blank" rel="noreferrer" className="termslink">l'informativa sulla privacy</a>
                <input type="checkbox" name="terms" placeholder={intl.formatMessage({id: 'contactus.form_email'})}
                       {...register('terms',{
                           required: <FormattedMessage id={"contactus.error_required"}/>,
                       })}
                       disabled={isSubmitting}/>
                <span className="checkmark"/>
            </label>
            {errors?.terms ? <ErrorIcon className="input-icon"/> : watch('terms') ?
                <SuccessIcon className="input-icon"/> : null}
            {errors?.terms && <span className="form-error-message">È necessario accettare i <a>termini e condizioni</a></span>}

            <div className="d-flex justify-content-end mt-4">
                <FormattedMessage id={"landingA.send"}>
                    {(msg) => <Button disabled={isSubmitting} type={'primary'} label={msg} arrow={true} id={"leadFormSubmit"} sending={isSubmitting}/>}
                </FormattedMessage>
            </div>

            {submitted && showConfirmationSent}
            {(errors && errors?.submit) && showSubmitError}
        </form>
    </>
};

export default injectIntl(DigitalTransformationForm);
